<template>
    <div>
        <div class="form-group">
            <div class="row">
              <a :href="`/login/google${appendOrg}`" class="btn btn-outline-dark btn-block mb-1 btn-login" v-if="adminFlags.flag_enable_sso_google" @mouseup="showWaitPage"> <img src="/vstatic/images/icons/btn-login-google.png" />{{ props.labelText }} with Google </a>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <a :href="`/login/okta${appendOrg}`" class="btn btn-outline-dark btn-block mb-1 btn-login" v-if="adminFlags && adminFlags.flag_enable_sso_okta" @mouseup="showWaitPage"> <img src="/vstatic/images/icons/btn-login-okta.png" />{{ props.labelText }} with Okta </a>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <a :href="`/login/github${appendOrg}`" class="btn btn-outline-dark btn-block mb-1 btn-login" v-if="adminFlags && adminFlags.flag_enable_sso_github" @mouseup="showWaitPage"> <img src="/vstatic/images/icons/btn-login-github.png" />{{ props.labelText }} with Github </a>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <a :href="`/login/azure${appendOrg}`" class="btn btn-outline-dark btn-block mb-1 btn-login" v-if="adminFlags && adminFlags.flag_enable_sso_azure" @mouseup="showWaitPage"> <img src="/vstatic/images/icons/btn-login-azure.png" />{{ props.labelText }} with Azure </a>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <a :href="`/login/slack${appendOrg}`" class="btn btn-outline-dark btn-block mb-1 btn-login" v-if="adminFlags && adminFlags.flag_enable_sso_slack" @mouseup="showWaitPage"> <img src="/vstatic/images/icons/btn-login-slack.png" />{{ props.labelText }} with Slack </a>
            </div>
          </div>
    </div>
</template>

<script setup>
const props = defineProps({
    adminFlags: {
        type: Object,
        required: true
    },
    labelText: {
      type: String,
      required: false,
      default: 'Login'
    }
});

const route = useRoute();
let appendOrg = "";
if ((route.query.org != '') && (typeof(route.query.org) != 'undefined')) {
  appendOrg = `?org=${route.query.org}`;
}
</script>

<style scoped>
.btn-block {
  width: 100%;
}
.btn-login {
  position: relative;
  border: 1px solid #ccc !important;
}
.btn-login:hover {
  background-color: white !important;
  color: black !important;
}
.btn-login img {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 5px;
}
</style>