<template>
    <div class="login-footer bg-white">
        <p class="text-center">© {{ year }} DagKnows. All rights reserved.</p>
    </div>
</template>
<script setup>
let year = (new Date()).getFullYear();
</script>
<style scoped>
.login-footer {
  position: absolute;
  widows: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  height: 30px;
  border: 1px solid grey;
  font-size: 12px;
}
.login-footer p {
  margin-top: 5px;
}
</style>